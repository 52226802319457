<template>
  <SystemPageWrapper>
    <div class="system-page__icon">
      <svg width="152" height="124" viewBox="0 0 152 124" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_9827_101)">
          <path d="M20 62C20 76.8521 25.9 91.0959 36.402 101.598C46.9041 112.1 61.1479 118 76 118C90.8521 118 105.096 112.1 115.598 101.598C126.1 91.0959 132 76.8521 132 62C132 47.1479 126.1 32.9041 115.598 22.402C105.096 11.9 90.8521 6 76 6C61.1479 6 46.9041 11.9 36.402 22.402C25.9 32.9041 20 47.1479 20 62Z" fill="#E5E7EB"/>
          <path d="M69.6427 54.8599H45.1446C43.981 54.8599 43.0377 55.8031 43.0377 56.9668V87.5531" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M69.5114 58.4941H46.7236V87.553" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M99.0794 91.1882H38.4938V88.6069C38.4938 88.0248 38.9657 87.5529 39.5477 87.553H98.0255C98.6075 87.553 99.0794 88.0248 99.0794 88.6069V91.1882Z" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M99.0794 91.1882C93.4516 92.7966 87.6291 93.6124 81.7759 93.6121H55.7973C49.9442 93.6124 44.1216 92.7966 38.4938 91.1882H99.0794Z" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M73.3849 79.8727C72.7872 79.8756 72.2132 79.6393 71.7909 79.2163L68.7848 76.2158L65.7786 79.2163C64.5537 80.4416 62.4618 79.8814 62.0131 78.2079C61.8049 77.4313 62.0268 76.6026 62.5953 76.0339L65.6014 73.0278L62.5972 70.017C61.3821 68.7823 61.9593 66.6952 63.6361 66.2603C64.4019 66.0617 65.2157 66.2797 65.7796 66.8346L68.7857 69.8407L71.7918 66.8346C73.0167 65.6075 75.1106 66.1666 75.5608 67.8409C75.7698 68.618 75.5483 69.4475 74.9799 70.017L71.9681 73.024L74.9742 76.0302C76.2013 77.253 75.6445 79.3455 73.972 79.7968C73.779 79.8489 73.58 79.875 73.3802 79.8746L73.3849 79.8727Z" fill="white" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M72.2109 30.3879H113.506V63.2884H72.2109V30.3879Z" fill="white" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M75.4111 34.175C75.4111 35.4072 76.7449 36.1772 77.812 35.5612C78.3072 35.2753 78.6123 34.7469 78.6123 34.175C78.6123 32.9429 77.2785 32.1728 76.2114 32.7888C75.7162 33.0748 75.4111 33.6032 75.4111 34.175Z" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M81.8116 34.175C81.8116 35.4072 83.1454 36.1772 84.2125 35.5612C84.7077 35.2753 85.0127 34.7469 85.0127 34.175C85.0127 32.9429 83.6789 32.1728 82.6118 32.7888C82.1166 33.0748 81.8116 33.6032 81.8116 34.175Z" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M88.2129 34.175C88.2129 35.4072 89.5467 36.1772 90.6138 35.5612C91.109 35.2753 91.4141 34.7469 91.4141 34.175C91.4141 32.9429 90.0803 32.1728 89.0132 32.7888C88.518 33.0748 88.2129 33.6032 88.2129 34.175Z" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M81.2678 46.4302C81.2678 47.6472 82.5852 48.4077 83.6391 47.7993C84.1282 47.5169 84.4295 46.995 84.4295 46.4302C84.4295 45.2132 83.1121 44.4526 82.0582 45.0611C81.5691 45.3435 81.2678 45.8654 81.2678 46.4302Z" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M101.288 46.4302C101.288 47.6472 102.605 48.4077 103.659 47.7993C104.148 47.5169 104.449 46.995 104.449 46.4302C104.449 45.2132 103.132 44.4526 102.078 45.0611C101.589 45.3435 101.288 45.8654 101.288 46.4302Z" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M105.928 53.3538C105.928 54.5124 104.674 55.2364 103.671 54.6572C103.205 54.3883 102.918 53.8915 102.918 53.3538C102.918 52.5231 104.423 49.5911 104.423 49.5911C104.423 49.5911 105.928 52.5231 105.928 53.3538Z" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M88.3357 57.7805C87.5709 57.779 87.0106 57.0597 87.1965 56.3177C88.3233 51.9591 93.7458 50.4547 96.9571 53.6098C97.7144 54.3538 98.2549 55.2899 98.5206 56.3177C98.7076 57.061 98.1449 57.7817 97.3785 57.7805C95.8896 57.4625 94.3771 57.2672 92.8562 57.1963C91.336 57.2673 89.8241 57.4627 88.3357 57.7805Z" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M72.2109 38.0007H113.506" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M94.5355 65.9373V87.553H90.8496V65.9373" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_9827_101">
            <rect width="112" height="112" fill="white" transform="translate(20 6)"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <h1 class="system-page__title heading-2-md grey-100">
      {{ $t('Web.NoConnection.ConnectionLost') }}
    </h1>
    <span class="system-page__subtitle body-1 grey-70">
      {{ $t('Web.NoConnection.FailToConnectUserManagement') }}
    </span>
    <SlLink
      variant="primary"
      @click.prevent="handleReload"
    >
      {{ $t('Web.NoConnection.ReloadPage') }}
    </SlLink>
  </SystemPageWrapper>
</template>

<script>
export default {
  name: 'NoConnection',
  methods: {
    handleReload() {
      window.location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/shared/page/system-page/no-connection";
</style>
